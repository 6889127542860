import React from 'react';
import { slugify } from '../../../utils/helpers'

export const Radio = (props) => {
  const { field, value, handleChange } = props
  const {name, required, className, options } = field
  return (
    <div className={`${className}${value[slugify(name)] ? ' active' : ''}${required ? ' required' : ''}`}>
        <div className="radio">
        {options && options.map((option, index) => (
          <label htmlFor={name} key={index}>
            <input name={slugify(name)} id={slugify(name)} type="radio" checked={value[slugify(name)] === option.value} onChange={handleChange} value={option.value} />
            {option.value}
          </label>
        ))}
        </div>
    </div>
  )
}
