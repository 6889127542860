import React from 'react';
import { graphql } from 'gatsby';
import GatsbyLink from '../components/GatsbyLink'
import Layout from '../components/Layout';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import { PageHeader } from '../components/PageHeader';
import { RenderShortcode } from '../components/RenderShortcode';
import './post.scss';
import { decodeEntities } from '../utils/helpers';

export const ProjectPostTemplate = ({
  content,
  categories,
  title,
  date,
  author
}) => {
  return (
    <section className="single-post">
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            <div className="wrap">
              <RenderShortcode content={content} />
            </div>
            {/* {categories && categories.length ? (
              <div className="taxonomy">
                <h4>Categories</h4>
                <ul className="taglist">
                  {categories.map(category => (
                    <li key={`${category.slug}cat`}>
                      {category.slug &&
                        <GatsbyLink to={`/reports/${category.slug}/`}>
                          {category.name}
                        </GatsbyLink>
                      }
                    </li>
                  ))}
                </ul>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    </section>
  )
}

const ProjectPost = ( props ) => {
  const { data, location } = props;
  const { wordpressPost: post, wordpressWpSettings } = data;
  if (!post) return null;
  const { title, content, featured_image_url, yoast, categories, date, author } = post;
  return (
    <Layout>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(wordpressWpSettings.title)}`}
        desc={yoast.metaDescription}
      />
      <PageHeader headerTitle={title} headerSubTitle={date} headerBackgroundImage={featured_image_url} location={location} />
      <ProjectPostTemplate
        content={content}
        categories={categories}
        title={title}
        date={date}
        author={author}
        blogSlug="reports"
      />
    </Layout>
  )
}

export default Previewable(ProjectPost, 'reports');

export const pageQuery = graphql`
  fragment ReportFields on wordpress__wp_reports {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProjectPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    wordpressPost: wordpressWpReports(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_image_url {
        source_url
      }
      categories {
        name
        slug
      }
      author {
        name
        slug
        avatar_urls {
          wordpress_48
        }
      },
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
  }
`
