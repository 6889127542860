import React from 'react';
import { slugify } from '../../../utils/helpers'

export const Email = (props) => {
  const { field, value, handleChange } = props
  const {name, placeholder, required, className } = field
  const slug = slugify(name)

  return (
    <div className={`${className}${value[slugify(name)] ? ' active' : ''}${required ? ' required' : ''}`}>
      <label htmlFor={slug}>{placeholder}</label>
      <input name={slug} id={slug} type="text" placeholder={placeholder} required={required ? true : false} onChange={handleChange} value={value[slugify(name)] ? value[slugify(name)] : ''} />
    </div>
  )
}
